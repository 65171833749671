import "dayjs/locale/fr";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MenuItem, TextField, Menu } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSelector, useDispatch } from "react-redux";
import CustomSelectInput from "../custom/CustomSelectInput";
import dayjs from "dayjs";
import IconeReset from "../../assets/icons/refresh--black.svg";
import IconeFleche from "../../assets/icons/arrow-toggle-black.svg";
import {
    changeStartDate,
    changeEndDate,
    selectEndDate,
    selectStartDate,
    changePeriod,
} from "../../reducers/filterSlice.jsx";

dayjs.extend(customParseFormat);
dayjs.locale("fr");

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    "&.active": {
        backgroundColor: "var(--blue-20)",
        color: "var(--blue-100)",
        border: "1px solid var(--blue-70)",
        "&:hover": {
            backgroundColor: "var(--blue-20)",
            border: "1px solid var(--blue-70)",
            color: "var(--blue-100)",
        },
    },
    "&:not(.active)": {
        backgroundColor: "transparent",
        color: "var(--black-100)",
        border: "1px solid var(--black-15)",
        "&:hover": {
            backgroundColor: "var(--blue-20)",
            border: "1px solid var(--blue-70)",
            color: "var(--blue-100)",
        },
    },
}));

const SelectBar = ({ loadData, dateRanges, hideFlexButton }) => {
    const dispatch = useDispatch();
    const { propertyId } = useParams();
    const globalStartDate = useSelector(selectStartDate);
    const globalEndDate = useSelector(selectEndDate);

    const [selectedPeriod, setSelectedPeriod] = useState(null);

    const [tempStartDate, setTempStartDate] = useState(dayjs(globalStartDate));
    const [tempEndDate, setTempEndDate] = useState(dayjs(globalEndDate));

    const [appliedStartDate, setAppliedStartDate] = useState(globalStartDate);
    const [appliedEndDate, setAppliedEndDate] = useState(globalEndDate);

    const [referenceStartDate, setReferenceStartDate] =
        useState(globalStartDate);
    const [referenceEndDate, setReferenceEndDate] = useState(globalEndDate);

    const [toSave, setToSave] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [compare, setCompare] = useState(false);
    const open = Boolean(anchorEl);

    const [selectedRangeTitle, setSelectedRangeTitle] =
        useState("Mois précédent");
    const [selectedOption, setSelectedOption] = useState("moisPrecedent");

    useEffect(() => {
        setTempStartDate(dayjs(globalStartDate));
        setTempEndDate(dayjs(globalEndDate));
        setAppliedStartDate(globalStartDate);
        setAppliedEndDate(globalEndDate);
    }, [globalStartDate, globalEndDate]);

    const sauvegardeDate = () => {
        const formattedStartDate = dayjs(tempStartDate).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(tempEndDate).format("YYYY-MM-DD");

        setAppliedStartDate(formattedStartDate);
        setAppliedEndDate(formattedEndDate);

        dispatch(changeStartDate(formattedStartDate));
        dispatch(changeEndDate(formattedEndDate));
        loadData();
    };

    const formatDate = (date) => {
        return dayjs(date).format("D MMM YYYY");
    };

    const ouvertureFiltre = (event) => {
        setAnchorEl(event.currentTarget);
        setReferenceStartDate(dayjs(tempStartDate));
        setReferenceEndDate(dayjs(tempEndDate));
    };

    const fermetureFiltre = () => {
        setAnchorEl(null);
    };

    const annulerChangements = () => {
        setTempStartDate(dayjs(referenceStartDate));
        setTempEndDate(dayjs(referenceEndDate));
        fermetureFiltre();
    };

    const appliquerChangements = () => {
        let title;

        const formattedStartDate = dayjs(tempStartDate).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(tempEndDate).format("YYYY-MM-DD");

        const debutAnneePrecedente = dayjs()
            .subtract(1, "year")
            .startOf("year")
            .format("YYYY-MM-DD");
        const finAnneePrecedente = dayjs()
            .subtract(1, "year")
            .endOf("year")
            .format("YYYY-MM-DD");

        const debut90DerniersJours = dayjs()
            .subtract(90, "days")
            .format("YYYY-MM-DD");
        const fin90DerniersJours = dayjs()
            .subtract(1, "day")
            .format("YYYY-MM-DD");

        const isCustomSelection = !(
            (selectedOption === "moisPrecedent" &&
                formattedStartDate ===
                    dayjs()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD") &&
                formattedEndDate ===
                    dayjs()
                        .subtract(1, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD")) ||
            (selectedOption === "moisEnCours" &&
                formattedStartDate ===
                    dayjs().startOf("month").format("YYYY-MM-DD") &&
                formattedEndDate === dayjs().format("YYYY-MM-DD")) ||
            (selectedOption === "derniersQuatreVingtDixJours" &&
                formattedStartDate === debut90DerniersJours &&
                formattedEndDate === fin90DerniersJours) ||
            (selectedOption === "anneePrecedente" &&
                formattedStartDate === debutAnneePrecedente &&
                formattedEndDate === finAnneePrecedente)
        );

        if (isCustomSelection) {
            title = "Personnalisé";
            setSelectedOption(null);
        } else {
            switch (selectedOption) {
                case "moisPrecedent":
                    title = "Mois précédent";
                    break;
                case "moisEnCours":
                    title = "Mois en cours";
                    break;
                case "derniersQuatreVingtDixJours":
                    title = "90 derniers jours";
                    break;
                case "anneePrecedente":
                    title = "Dernière année civile";
                    break;
                default:
                    title = "Mois précédent";
            }
        }

        if (selectedPeriod) {
            dispatch(changePeriod(selectedPeriod));
            loadData();
            setCompare(true);
        } else {
            setCompare(false);
        }

        dispatch(changeStartDate(tempStartDate.format("YYYY-MM-DD")));
        dispatch(changeEndDate(tempEndDate.format("YYYY-MM-DD")));

        setSelectedRangeTitle(title);
        fermetureFiltre();
        sauvegardeDate();
    };

    const dateChangementFiltre = (range, title) => {
        let dateDebut, dateFin;

        switch (range) {
            case "moisPrecedent":
                dateDebut = dayjs().subtract(1, "month").startOf("month");
                dateFin = dayjs().subtract(1, "month").endOf("month");
                break;
            case "moisEnCours":
                dateDebut = dayjs().startOf("month");
                dateFin = dayjs();
                break;
            case "derniersQuatreVingtDixJours":
                dateDebut = dayjs().subtract(90, "days");
                dateFin = dayjs().subtract(1, "days");
                break;
            case "anneePrecedente":
                dateDebut = dayjs().subtract(1, "year").startOf("year");
                dateFin = dayjs().subtract(1, "year").endOf("year");
                break;
            default:
                dateDebut = dayjs();
                dateFin = dayjs();
        }

        setTempStartDate(dateDebut);
        setTempEndDate(dateFin);
        setSelectedOption(range);
        setSelectedRangeTitle(title);

        setAppliedStartDate(dateDebut.format("YYYY-MM-DD"));
        setAppliedEndDate(dateFin.format("YYYY-MM-DD"));
    };

    const reinitialiserDates = () => {
        const dateDebut = dayjs().subtract(1, "month").startOf("month");
        const dateFin = dayjs().subtract(1, "month").endOf("month");

        setTempStartDate(dateDebut);
        setTempEndDate(dateFin);
        setAppliedStartDate(dateDebut.format("YYYY-MM-DD"));
        setAppliedEndDate(dateFin.format("YYYY-MM-DD"));

        dispatch(changeStartDate(dateDebut.format("YYYY-MM-DD")));
        dispatch(changeEndDate(dateFin.format("YYYY-MM-DD")));

        setSelectedRangeTitle("Mois précédent");
        setSelectedOption("moisPrecedent");

        setSelectedPeriod(null);

        setCompare(false);

        dispatch(changePeriod("data_0"));
        fermetureFiltre();
        loadData();
    };

    const handleSelectPeriod = (period) => {
        setSelectedPeriod(period);
    };

    useEffect(() => {
        dispatch(
            changeStartDate(
                dayjs()
                    .subtract(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD")
            )
        );
        dispatch(
            changeEndDate(
                dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
            )
        );
        dispatch(changePeriod("data_0"));
        setSelectedOption("moisPrecedent");
    }, [propertyId, dispatch]);

    return (
        <div className="input-datepicker">
            <div
                className="input-datepicker-affichage"
                onClick={ouvertureFiltre}
            >
                <span>{selectedRangeTitle}</span>
                <p>
                    {formatDate(appliedStartDate)} -{" "}
                    {formatDate(appliedEndDate)}
                </p>
                <button>
                    <img src={IconeFleche} alt="Suivant" />
                </button>
            </div>
            <Menu
                id="date-range-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={fermetureFiltre}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <div key={0} className="selectbar-item">
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr"
                    >
                        <div className="datepickers-container">
                            <DatePicker
                                label="Date de début"
                                value={dayjs(tempStartDate)}
                                maxDate={dayjs().subtract(1, "day")}
                                onChange={(newValue) => {
                                    setTempStartDate(newValue);
                                    setToSave(true);
                                    setSelectedRangeTitle("Personnalisé");
                                    setSelectedOption(null);
                                    setAppliedStartDate(
                                        newValue.format("YYYY-MM-DD")
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            readOnly: true,
                                        }}
                                    />
                                )}
                            />
                            {" - "}
                            <DatePicker
                                label="Date de fin"
                                value={dayjs(tempEndDate)}
                                minDate={dayjs(tempStartDate)}
                                onChange={(newValue) => {
                                    setTempEndDate(newValue);
                                    setToSave(true);
                                    setSelectedRangeTitle("Personnalisé");
                                    setSelectedOption(null);
                                    setAppliedEndDate(
                                        newValue.format("YYYY-MM-DD")
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            readOnly: true,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </div>
                <hr key={1} className="hr-texte" data-content="OU"></hr>
                <div key={2} className="date-menu-list">
                    <CustomMenuItem
                        className={
                            selectedOption === "moisPrecedent" ? "active" : ""
                        }
                        onClick={() =>
                            dateChangementFiltre(
                                "moisPrecedent",
                                "Mois précédent"
                            )
                        }
                    >
                        Mois précédent
                    </CustomMenuItem>
                    <CustomMenuItem
                        className={
                            selectedOption === "moisEnCours" ? "active" : ""
                        }
                        onClick={() =>
                            dateChangementFiltre("moisEnCours", "Mois en cours")
                        }
                    >
                        Mois en cours
                    </CustomMenuItem>
                    <CustomMenuItem
                        className={
                            selectedOption === "derniersQuatreVingtDixJours"
                                ? "active"
                                : ""
                        }
                        onClick={() =>
                            dateChangementFiltre(
                                "derniersQuatreVingtDixJours",
                                "90 derniers jours"
                            )
                        }
                    >
                        90 derniers jours
                    </CustomMenuItem>
                    <CustomMenuItem
                        className={
                            selectedOption === "anneePrecedente" ? "active" : ""
                        }
                        onClick={() =>
                            dateChangementFiltre(
                                "anneePrecedente",
                                "Dernière année civile"
                            )
                        }
                    >
                        Dernière année civile
                    </CustomMenuItem>
                </div>
                {!hideFlexButton && [
                    <hr key={3} className="hr-texte"></hr>,
                    <CustomSelectInput
                        key={4}
                        dateRanges={dateRanges}
                        hideFlexButton={hideFlexButton}
                        onSelectPeriod={handleSelectPeriod}
                    />,
                ]}
                <hr key={5} className="hr-texte"></hr>
                <div key={6} className="filtreActions">
                    <MenuItem onClick={annulerChangements}>Annuler</MenuItem>
                    <hr></hr>
                    <MenuItem id="btn-reset" onClick={reinitialiserDates}>
                        Réinitialiser
                        <img
                            src={IconeReset}
                            alt="Icône Reset - Altelis Monitor"
                        />
                    </MenuItem>
                    <MenuItem onClick={appliquerChangements}>
                        Appliquer
                    </MenuItem>
                </div>
            </Menu>
        </div>
    );
};

export default SelectBar;
