import React from "react";
// import { useSelector } from "react-redux";
// import { selectPeriod } from "../../reducers/filterSlice";
import CustomBigCard from "../custom/CustomBigCard";
import CustomDoughnut from "../custom/CustomDoughnut";

const AmountAll = ({ data, period }) => {
    const renderDoughnut = (label, periodValue) =>
        period !== "data_0" && (
            <CustomDoughnut label={label} data={data} period={periodValue} />
        );

    console.log(data);

    return (
        <div
            className={
                data ? "global-amount-container" : "analytics-row is_big"
            }
        >
            {data ? (
                <>
                    <CustomBigCard
                        data_first={data[0]}
                        data_second={data[1]}
                        data_third={data[2]}
                        data_fourth={data[3]}
                        period={period}
                        isCompare
                        isForDoughnut
                        tooltip="Correspond à l'ensemble des réservations passées grâce à votre site internet"
                    />
                    <div className="global-amount-charts">
                        <CustomDoughnut
                            label="Période actuelle"
                            data={data}
                            period={"data_0"}
                        />
                        {renderDoughnut("Période précédente", period)}
                    </div>
                </>
            ) : (
                <div className="analytics-row is_big">
                    <p>
                        Des données sur les réservations sont manquantes pour
                        vous apporter les informations nécessaires à votre
                        hôtel.
                    </p>
                </div>
            )}
        </div>
    );
};

export default AmountAll;
