import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { selectPeriod } from "../../../../reducers/filterSlice";
import Analytics from "../../Analytics";
import Ads from "../../Ads";
import Meta from "../../Meta";
import AmountAll from "../../AmountAll";

const TabReservations = ({ api, isLoaded }) => {
    const period = useSelector(selectPeriod);
    const { propertyId } = useParams();

    const affichageCard = (data) => {
        return (
            Array.isArray(data) &&
            data.some(
                (item) =>
                    item.data_0 !== 0 ||
                    item.data_1 !== 0 ||
                    item.data_2 !== 0 ||
                    item.data_3 !== 0
            )
        );
    };

    const content = useMemo(() => {
        if (!isLoaded) {
            return (
                <div className="loader-container">
                    <CircularProgress size={30} />
                </div>
            );
        }

        if (!api) {
            return (
                <div className="error-message">
                    Erreur lors de la récupération des données
                </div>
            );
        }

        return (
            <div className="main-router-content">
                <div className="view-container">
                    {affichageCard(api.all) && (
                        <div className="transactions-container parts">
                            <div className="header-title parts">
                                <h2>Réservations directes</h2>
                            </div>
                            <AmountAll data={api.all} period={period} />
                        </div>
                    )}
                    <div className="transactions-container analytics">
                        <div className="header-title analytics">
                            <h2>Google Analytics</h2>
                        </div>
                        <Analytics
                            data={api.analytics}
                            period={period}
                            property={{ id: propertyId, ...api.property }}
                        />
                    </div>
                    <div className="transactions-container adwords">
                        <div className="header-title ads">
                            <h2>Google Ads</h2>
                        </div>
                        <Ads data={api.ads} period={period} />
                    </div>
                    <div className="transactions-container metas">
                        <div className="header-title meta">
                            <h2>Métas</h2>
                        </div>
                        <Meta
                            key={`meta-${period}-${Date.now()}`}
                            meta={api.meta}
                            period={period}
                            property={{ id: propertyId, ...api.property }}
                        />
                    </div>
                </div>
            </div>
        );
    }, [api, isLoaded, period, propertyId]);

    return content;
};

export default TabReservations;
