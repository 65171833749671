import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
const CustomerList = lazy(() => import('./CustomerList'));
const CustomerPanel = lazy(() => import('../../customers/CustomerPanel'));

const Customers = () => {
    return (
        <Suspense fallback={<div>Chargement...</div>}>
            <Routes>
                <Route path=":propertyId" element={<CustomerPanel adminMode />} />
                <Route path="*" element={<CustomerList />} />
            </Routes>
        </Suspense>
    );
}

export default Customers;