import axios from "axios";
import dayjs from "dayjs";

// const DOMAIN_API = '/api/v1';

async function fetch_api_json(method, suffix_url) {
    try {
        const response = await fetch(
            process.env.REACT_APP_BACKEND_API_URL + suffix_url,
            {
                method: method,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache, no-store, must-revalidate",
                    Pragma: "no-cache",
                    Expires: "0",
                },
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

export function logout() {
    return axios
        .post(
            process.env.REACT_APP_BACKEND_API_URL + "accounts/log_out",
            null,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

// Users
export function current_user() {
    return axios
        .get(process.env.REACT_APP_BACKEND_API_URL + "user/me", {
            withCredentials: true,
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

// PROPERTIES //

export function accessible_properties() {
    return fetch_api_json("GET", "/accessible_properties");
}

// Admin
export function property_list() {
    return axios
        .get(process.env.REACT_APP_BACKEND_API_URL + "/property", {
            withCredentials: true,
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export function property_by_id(id) {
    return fetch_api_json("GET", `/property/${id}`);
}

// REPORTS //

// Accounts
export function ga_ga4_accounts() {
    return fetch_api_json("GET", "/ga/ga4/accounts");
}

export function ga_universal_accounts() {
    return fetch_api_json("GET", "/ga/universal/accounts");
}
export function ga_full_accounts() {
    return fetch_api_json("GET", "/ga/full/accounts");
}

export function ga_full_account(account_id, ga_type, property_id) {
    const encoded = encodeURIComponent(property_id);
    return fetch_api_json(
        "GET",
        `/ga/full/account/${account_id}/${ga_type}/${encoded}`
    );
}

// Google Analytics (GA4 and Universal)
export function analytics_report(property_id, start_date, end_date) {
    const qs = `start_date=${start_date}&end_date=${end_date}`;
    return fetch_api_json("GET", `/property/${property_id}/ga/report?${qs}`);
}

// Google Analytics GA4
export function ga_ga4_report(property_id, start_date, end_date) {
    return analytics_report(property_id, start_date, end_date);
}

// Admin Google Analytics Function
export function ga_ga4_reports() {
    return fetch_api_json("GET", "/ga/ga4/reports");
}
export function ga_ga4_report_direct(
    account_id,
    property_id,
    start_date,
    end_date
) {
    //  const query = {"contacts" : contact, "entities": entite, "themes" : theme, types: []};
    //  let encode = encodeURIComponent(JSON.stringify(query));
    const encoded = encodeURIComponent(property_id);
    const qs = `start_date=${start_date}&end_date=${end_date}`;
    return fetch_api_json(
        "GET",
        `/ga/ga4/report/${account_id}/${encoded}?${qs}`
    );
}

// Google Analytics Universal
export function ga_universal_report(property_id, start_date, end_date) {
    return analytics_report(property_id, start_date, end_date);
}

// All reports
export function all_reports(property_id, start_date, end_date) {
    const url =
        process.env.REACT_APP_BACKEND_API_URL +
        "property/" +
        property_id +
        "/all/report/?start_date=" +
        start_date +
        "&end_date=" +
        end_date;
    return axios
        .get(url, {
            withCredentials: true,
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

// Macro reports
export function macro_reports(start_date, end_date) {
    const url =
        process.env.REACT_APP_BACKEND_API_URL +
        "/properties/all/report/?start_date=" +
        start_date +
        "&end_date=" +
        end_date;
    return axios
        .get(url, {
            withCredentials: true,
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

// Admin function
export function ga_universal_reports() {
    return fetch_api_json("GET", "/ga/universal/reports");
}
export function ga_universal_report_direct(
    account_id,
    property_id,
    start_date,
    end_date
) {
    const qs = `start_date=${start_date}&end_date=${end_date}`;

    const encoded = encodeURIComponent(property_id);
    return fetch_api_json(
        "GET",
        `/ga/universal/report/${account_id}/${encoded}?${qs}`
    );
}

// Métas stats
export function get_meta_stats(property_id, start_date, end_date) {
    const qs = `start_date=${start_date}&end_date=${end_date}`;

    return fetch_api_json("GET", `/property/${property_id}/meta/stats?${qs}`);
}

// Métas bookings
export function get_meta_bookings(property_id, start_date, end_date) {
    const url = `${process.env.REACT_APP_BACKEND_API_URL}property/${property_id}/meta/bookings?start_date=${start_date}&end_date=${end_date}`;
    console.log(url);
    return axios
        .get(url, {
            withCredentials: true,
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
}

// Métas cost
export function get_meta_cost(hotelCode, start_date, end_date) {
    return axios
        .post(`${process.env.REACT_APP_TOOLS_API_URL}metas/cost`, {
            hotelCode: hotelCode.toString(),
            startDate: start_date,
            endDate: end_date,
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
}

// Google Ads
export function get_ads_report(property_id, start_date, end_date) {
    const qs = `start_date=${start_date}&end_date=${end_date}`;

    return fetch_api_json("GET", `/property/${property_id}/ads/report?${qs}`);
}
