import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { all_reports } from "../../api";
import { selectEndDate, selectStartDate } from "../../reducers/filterSlice";
import { selectUser } from "../../reducers/userSlice";
import { globalAds, globalAll, globalMeta } from "../admin/functionGlobal";
import dayjs from "dayjs";
import SelectBar from "./SelectBar";
import TabLivie from "./tabs/TabLivie/TabLivie";
import TabReservations from "./tabs/TabReservations/TabReservations";
import CustomExportPDF from "../custom/CustomExportPdf";
import CustomExportConversations from "../custom/CustomExportConversations";
import "./tabs/CustomTabs.scss";

const CustomerPanel = ({ adminMode }) => {
    const [tabValue, setTabValue] = useState(null); // On ne définit pas encore l'onglet actif
    const [isLoaded, setIsLoaded] = useState(false);
    const [property, setProperty] = useState(null);
    const [api, setApi] = useState(null);
    const [key, setKey] = useState(0);
    const user = useSelector(selectUser);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const [dateRanges, setDateRanges] = useState([
        { start_date: startDate, end_date: endDate },
    ]);
    const params = useParams();
    const [showExportButton, setShowExportButton] = useState(false);
    const [hasReservationsData, setHasReservationsData] = useState(false);

    useEffect(() => {
        if (user) {
            const newProperty = adminMode
                ? user.properties.find(
                      (p) => p.id === parseInt(params.propertyId)
                  )
                : user.properties[0];
            setProperty(newProperty);
            if (newProperty) {
                loadData(newProperty);
            }
        }
    }, [user, adminMode, params.propertyId]);

    const loadData = useCallback(
        async (property) => {
            if (!property || !property.id) {
                setIsLoaded(false);
                setShowExportButton(false);
                setTabValue(1);
                return;
            }

            setIsLoaded(false);
            setDateRanges([{ start_date: startDate, end_date: endDate }]);

            try {
                const response = await all_reports(
                    property.id,
                    dayjs(startDate).format("YYYY-MM-DD"),
                    dayjs(endDate).format("YYYY-MM-DD")
                );

                let metaData = null;
                if (response.data.meta && Array.isArray(response.data.meta)) {
                    const metaCostSafe = response.data.meta_cost
                        ? response.data.meta_cost
                        : { datesRange: [] };

                    try {
                        metaData = globalMeta(response.data.meta, metaCostSafe);
                    } catch (error) {
                        console.error(
                            "Erreur de traitement des métadonnées :",
                            error
                        );
                        metaData = null;
                    }
                }

                const apiData = {
                    analytics: response.data.ga?.data || null,
                    ads: response.data.ads
                        ? globalAds(response.data.ads)
                        : null,
                    meta: metaData,
                    all: response.data ? globalAll(response.data) : null,
                };

                setApi(apiData);
                setIsLoaded(true);

                const hasReservationsData =
                    (apiData.analytics && apiData.analytics.length > 0) ||
                    (apiData.ads &&
                        apiData.ads.data &&
                        apiData.ads.data.some((item) => item.data_0 > 0)) ||
                    (apiData.meta &&
                        apiData.meta.data &&
                        apiData.meta.data.some((item) => item.data_0 > 0)) ||
                    (apiData.all &&
                        apiData.all.some((item) => item.data_0 > 0));

                setHasReservationsData(hasReservationsData);
                setShowExportButton(hasReservationsData);

                if (hasReservationsData && tabValue === null) {
                    setTabValue(0);
                } else if (!hasReservationsData) {
                    setTabValue(1);
                }
            } catch (error) {
                console.error(
                    "[CustomerPanel] Erreur de chargement des données :",
                    error
                );
                setIsLoaded(true);
                setShowExportButton(false);
                setHasReservationsData(false);
                setTabValue(1);
            }
        },
        [startDate, endDate, tabValue]
    );

    const reloadData = useCallback(() => {
        setIsLoaded(false);
        setKey((prevKey) => prevKey + 1);
        if (property) {
            loadData(property);
        }
    }, [property, loadData]);

    useEffect(() => {
        if (property && (startDate || endDate)) {
            reloadData();
        }
    }, [property, startDate, endDate, reloadData]);

    const handleTabChange = (tabIndex) => {
        setTabValue(tabIndex);
        if (tabIndex === 0 && hasReservationsData) {
            loadData(property);
        }
    };

    return (
        <div className="main-component">
            <div className="tab-container">
                <div className="tab-container-left">
                    <div className="tab-container-background">
                        {hasReservationsData && (
                            <button
                                className={`tab-link ${
                                    tabValue === 0 ? "tab-active" : ""
                                } tab-link-small`}
                                onClick={() => handleTabChange(0)}
                                style={{
                                    display: hasReservationsData
                                        ? "flex"
                                        : "none",
                                }}
                            >
                                <span>Réservations</span>
                            </button>
                        )}
                        <button
                            className={`tab-link ${
                                tabValue === 1 ? "tab-active" : ""
                            } tab-link-large`}
                            onClick={() => handleTabChange(1)}
                        >
                            <span
                                className={`new-tag ${
                                    tabValue === 1 && hasReservationsData
                                        ? "active-with-reservations"
                                        : tabValue === 1 && !hasReservationsData
                                        ? "active-no-reservations"
                                        : "default"
                                }`}
                            >
                                New
                            </span>
                            <span>Livie by Altelis</span>
                            <svg
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.75247 0.993605C4.8541 0.684585 5.29124 0.684586 5.39287 0.993606L6.4585 4.23378C6.49195 4.33547 6.5717 4.41523 6.67339 4.44868L9.91357 5.51431C10.2226 5.61594 10.2226 6.05308 9.91357 6.15471L6.67339 7.22034C6.5717 7.25379 6.49195 7.33355 6.4585 7.43524L5.39287 10.6754C5.29124 10.9844 4.8541 10.9844 4.75247 10.6754L3.68683 7.43524C3.65339 7.33355 3.57363 7.25379 3.47194 7.22034L0.231765 6.15471C-0.0772553 6.05308 -0.0772546 5.61594 0.231765 5.51431L3.47194 4.44867C3.57363 4.41523 3.65339 4.33547 3.68683 4.23378L4.75247 0.993605Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M10.5653 8.88694C10.6202 8.72014 10.8561 8.72014 10.911 8.88694L11.4862 10.6359C11.5043 10.6908 11.5473 10.7339 11.6022 10.7519L13.3512 11.3272C13.518 11.382 13.518 11.618 13.3512 11.6728L11.6022 12.248C11.5473 12.2661 11.5043 12.3092 11.4862 12.364L10.911 14.113C10.8561 14.2798 10.6202 14.2798 10.5653 14.113L9.9901 12.364C9.97205 12.3092 9.92899 12.2661 9.8741 12.248L8.1251 11.6728C7.9583 11.618 7.9583 11.382 8.1251 11.3272L9.8741 10.7519C9.92899 10.7339 9.97205 10.6908 9.9901 10.6359L10.5653 8.88694Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                        <div
                            className={`tab-background ${
                                tabValue === 1 && !hasReservationsData
                                    ? "tab-background-livie single-tab"
                                    : tabValue === 1
                                    ? "tab-background-livie"
                                    : "tab-background-reservations"
                            }`}
                        ></div>
                    </div>
                </div>
                <div className="tab-container-right">
                    {showExportButton && tabValue === 0 && property && api && (
                        <CustomExportPDF
                            dateRanges={dateRanges}
                            property={property}
                            data={api}
                        />
                    )}
                    {showExportButton && tabValue === 0 && api && <hr />}
                    {tabValue === 1 && property?.livie_id && (
                        <CustomExportConversations
                            startDate={startDate}
                            endDate={endDate}
                            properties={user.properties}
                        />
                    )}
                    {showExportButton &&
                        tabValue === 1 &&
                        property?.livie_id && <hr />}
                    {!hasReservationsData && property?.livie_id && <hr />}
                    {property?.livie_id || tabValue === 0 ? (
                        <SelectBar
                            loadData={reloadData}
                            dateRanges={dateRanges}
                            hideFlexButton={tabValue === 1}
                            tabLivieAffichage={tabValue === 1}
                        />
                    ) : null}
                </div>
            </div>

            {tabValue === 0 && hasReservationsData && (
                <TabReservations
                    key={key}
                    api={api}
                    isLoaded={isLoaded}
                    adminMode={adminMode}
                />
            )}
            {tabValue === 1 && property && (
                <TabLivie
                    property={property}
                    startDate={startDate}
                    endDate={endDate}
                    isAdmin={user.role === "admin"}
                />
            )}
        </div>
    );
};

export default CustomerPanel;
